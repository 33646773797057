import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Constants } from '../lib/const/constants';
import { PrimaryButton } from '../components/button';
import toast from 'react-hot-toast';
function BulkUpload() {
    const [file, setFile] = useState()
    const [uploading, setUploading] = useState(false);
    // const [loading, setLoading] = useState(false);
    // const [uploadedDocuments, setuploadedDocuments] = useState([]);
    const reference = useRef(null)
    const handleSingleFileChange = (event) => {
        setFile(event.target.files[0])
    };
    const getBase64 = async (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    function handleSubmit() {
        if (file != null && file != '') {
            setUploading(true)
            getBase64(file, (result) => {
                uploadSingleDocument(result)
            });
        }
        else
            toast.error('Please upload file.')
    }
    // useEffect(() => {
    //     getData()
    // },[])
    // const getData = async () => {
    //     setLoading(true)
    //     const token = sessionStorage.getItem('login_token');
    //     const login_workspaces_id = sessionStorage.getItem('login_workspaces_id');
    //     await axios.post(Constants.BASE_URL + 'API-FX-195-GET-BULK-UPLOAD-DOCUMENTS', {
    //         workspace_id: login_workspaces_id
    //     }, {
    //         headers: {
    //             Authorization: "Bearer " + JSON.parse(token),
    //             fx_key: Constants.SUBSCRIPTION_KEY
    //         }
    //     }).then((response) => {
    //         console.log(JSON.stringify(response.data.documents));
    //         setuploadedDocuments(response.data.documents)
    //         setLoading(false)
    //     }).catch(err => {
    //         err
    //         setLoading(false)
    //     });
    // }
    const uploadSingleDocument = async (document) => {
        const token = sessionStorage.getItem('login_token');
        await axios.post(Constants.BASE_URL + 'API-FX-195-BULK-UPLOAD-DOCUMENTS-TRANSACTIONS', {
            file: document,
        }, {
            headers: {
                Authorization: "Bearer " + JSON.parse(token),
                fx_key: Constants.SUBSCRIPTION_KEY
            }
        }).then((response) => {
            console.log(response.data);
            toast.success('Uploaded Successfully.')
            setUploading(false)
            reference.current.value = "";
        }).catch(err => {
            err
            toast.error('Something went wrong.Please try again later.')
            setUploading(false)
        });
    }
    return (
        <div className="my-2">

            <div className="flex items-center justify-between py-4 mb-4">
                <div className="flex items-center ml-7">
                    <span
                        className="font-medium text-lg border-l-4 border-l-[#F4CE14] pl-3">Bulk Upload</span>
                </div>
                <div className="flex space-x-4">
                    <a href='/testUpload.csv'>Download Test File</a>
                </div>
            </div>
            <div className="flex relative overflow-x-auto rounded-3xl bg-white p-5">
                <div>
                    <input type='file' accept='.csv' ref={reference} onChange={(event) => handleSingleFileChange(event)} />
                </div>
                <div style={{ width: '10%' }}>
                    <PrimaryButton
                        label={"Upload"}
                        onClick={() => handleSubmit()}
                        loading={uploading}
                    />
                </div>
            </div>
            {/* <div className="flex relative overflow-x-auto rounded-3xl bg-white p-5 my-5">
                <table className="w-full text-sm text-center">
                    <thead className="border-b-2">
                        <tr>
                            <th scope="col" className="px-4 py-6">Sno</th>
                            <th scope="col" className="px-4 py-6">Document</th>
                            <th scope="col" className="px-4 py-6">Date</th>
                        </tr>
                    </thead>
                    <tbody className="table-striped">
                        {loading ?
                            <tr aria-colspan={3}>
                                <td scope="col" className="px-4 py-6">loading...</td>
                            </tr>
                            :

                            uploadedDocuments.length > 0 && uploadedDocuments.map((document,index) => {
                                return (
                                    <tr key={index} aria-colspan={3}>
                                        <td scope="col" className="px-4 py-6">{index+1}</td>
                                        <td scope="col" className="px-4 py-6"><a href={Constants.FILE_PATH_BASE_URL+document.document}>View</a></td>
                                        <td scope="col" className="px-4 py-6">{document.created_at}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div> */}
        </div>
    );
}

export default BulkUpload;