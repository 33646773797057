import React, { useEffect, useState } from 'react';
import { BOTTOM_LINKS, NAVIGATION_LINKS, ADMIN_NAVIGATION_LINKS } from '../../lib/const/navbar-links';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Constants } from '../../lib/const/constants';

const linkClass =
  'flex items-center gap-2 py-3 px-4 hover:no-underline text-sm font-normal text-slate-900 mb-1';
const activeClass = 'bg-[#f1545e] rounded text-white w-full';

export default function Sidebar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showconvertTab, setshowconvertTab] = useState(false);
  const [navlink, setnavlink] = useState([]);
  const [loading, setLoading] = useState(false);
  const logout = async () => {
    sessionStorage.clear();
    navigate('/login')
  }
  const setAsyncData = async (key, value) => {
    sessionStorage.setItem(key, value);
  }
  const loadData = async () => {
    const login_workspaces_id = sessionStorage.getItem('login_workspaces_id');
    const login_token = sessionStorage.getItem('login_token');
    setAsyncData('balances', "");
    setAsyncData('conversions', "");
    await axios.get(Constants.BASE_URL + 'API-FX-165-CCTRANSACTIONS/' + login_workspaces_id + '?page=1&from=1970-01-01&to=1970-02-01', {
      headers: {
        Authorization: "Bearer " + JSON.parse(login_token),
        fx_key: Constants.SUBSCRIPTION_KEY
      }
    }).then(resp => {
      console.log("menu tabs" + JSON.stringify(resp.data.menu));

      if (resp.data.menu.length > 0) {
        for (var m = 0; m < resp.data.menu.length; m++) {
          if (resp.data.menu[m].tab_id == 2) {
            setAsyncData('balances', JSON.stringify(resp.data.menu[m].tab_id));
          }
          if (resp.data.menu[m].tab_id == 1) {
            setAsyncData('conversions', JSON.stringify(resp.data.menu[m].tab_id));
          }
        }
        setLoading(false)
        return true;
      }
      else {
        setLoading(false)
        setAsyncData('balances', "");
        setAsyncData('conversions', "");
      }
    }).catch(err => {
      console.log(err);
      setLoading(false)
    })


    const conversionstab = sessionStorage.getItem('conversions');
    const admin_login = sessionStorage.getItem('admin_login');
    if(admin_login=='1')
    {
      setnavlink(ADMIN_NAVIGATION_LINKS)
    }
    else
    {
      setnavlink(NAVIGATION_LINKS)
    }
    if (conversionstab) {
      setshowconvertTab(true);
    }
  }
  useEffect(() => {
    loadData()
  })
  return (
    <div className="w-64 flex flex-col text-white py-3 mr-4 mt-6">
      {!loading &&
      <div className="flex-1 px-4">
        {navlink.map((item) => (
          <div key={item.key}>
            {item.key == 'logout' ?
              <button key={item.key} onClick={logout} className={
                classNames(pathname === item.path ? activeClass : '', linkClass)
              }>
                <span className="text-xl">{item.icon}</span>
                {item.label}
              </button>
              :
              <>
                {item.key == 'convert' && showconvertTab ?
                  <button key={item.key} onClick={() => navigate(item.path)} className={
                    classNames(pathname === item.path ? activeClass : '', linkClass)
                  }>
                    <span className="text-xl">{item.icon}</span>
                    {item.label}
                  </button>
                  :
                  item.key != 'convert' ?
                    <button key={item.key} onClick={() => navigate(item.path)} className={
                      classNames(pathname === item.path ? activeClass : '', linkClass)
                    }>
                      <span className="text-xl">{item.icon}</span>
                      {item.label}
                    </button>
                    : ""}
              </>
            }
          </div>
        ))}
      </div> }
      <div className="px-4">
        {BOTTOM_LINKS.map((item) => (
          <div key={item.key}>
            {item.key == 'logout' ?
              <button key={item.key} onClick={logout} className={linkClass}>
                <span className="text-xl">{item.icon}</span>
                {item.label}
              </button>
              :
              <button key={item.key} onClick={() => navigate(item.path)} className={linkClass}>
                <span className="text-xl">{item.icon}</span>
                {item.label}
              </button>}
          </div>
        ))}
      </div>
    </div>
  );
}